import React from "react";
import { Box, Button } from "@chakra-ui/react";
import Accordion from "../../components/accordion";

const QuickQuestionsComp = ({ questions, btnText }) => {
  return (
    <Box p="0" w="full">
      {questions?.map((item, idx) => (
        <Accordion key={idx} question={item.question} answer={item.answer} />
      ))}
    </Box>
  );
};

export default QuickQuestionsComp;
