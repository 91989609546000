import { graphql, useStaticQuery } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

const usePrismicQuickQuestions = () => {
  const { language } = useI18next();
  const newLang =
    language === "en" ? "en-us" : language === "ru" ? "ru" : "hy-am";

  const response = useStaticQuery(graphql`
    query PrismicQuickQuestions {
      allPrismicQuickquestions(filter: { tags: { in: "quck_questions" } }) {
        nodes {
          lang
          data {
            navtext
            title {
              text
            }
            buttontext
            questionabout {
              about {
                text
              }
              type
            }
            qanswer {
              answer {
                richText
              }
              question {
                text
              }
              type
            }
          }
        }
      }
    }
  `);
  return sanitize(
    response.allPrismicQuickquestions.nodes.filter(
      (item) => item.lang === newLang
    )[0].data
  );
};

const sanitize = (response) => {
  return {
    navText: response.navtext,
    title: response.title.text,
    buttonText: response.buttontext,
    questionAbout: response.questionabout.map((question) => ({
      text: question.about.text,
      type: question.type,
    })),
    qAnswer: response.qanswer.map((item) => ({
      answer: item.answer.richText,
      question: item.question.text,
      type: item.type,
    })),
  };
};

export default usePrismicQuickQuestions;
