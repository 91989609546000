import * as React from "react";
import {
  Container,
  Heading,
  HStack,
  Select,
  Box,
  Stack,
} from "@chakra-ui/react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Seo from "../../components/SEO";
import Main from "../../components/layouts/Main";
import SmallNav from "../../components/smallNav";
import QuickQuestionsComp from "../../components/quickQuestions/QuickQuestions";
import usePrismicQuickQuestions from "../../hooks/usePrismicQuickQuestions";
const QuickQuestions = () => {
  const { language } = useTranslation();
  const quickQuestionsData = usePrismicQuickQuestions();
  const [active, setActive] = React.useState(
    quickQuestionsData?.questionAbout?.[0]?.type
  );

  return (
    <Main>
      <Container maxW="7xl" mb="13.5rem">
        <Seo
          title={quickQuestionsData.navText}
          description={quickQuestionsData.navText}
          link={`https://www.fsm.am/${language}/{t("quick-questions")}`}
        />
        <SmallNav>{quickQuestionsData.navText}</SmallNav>

        <Stack
          direction={{ base: "column-reverse", md: "row" }}
          spacing="6.25rem"
          mt="2.5rem"
        >
          <Box
            py="4"
            border="1px"
            borderColor="border.100"
            borderRadius="12px"
            maxW="17rem"
            maxH="23.875rem"
          >
            {quickQuestionsData.questionAbout?.map((item, idx) => (
              <Box
                key={`about-${item}-${idx}`}
                as="Button"
                fontSize="sm"
                color={active === item.type ? "white" : "grey.100"}
                py="10px"
                pl="4"
                pr="8"
                textAlign="left"
                w="full"
                bg={active === item.type ? "green.200" : "transparent"}
                _hover={{ bg: "green.200", color: "white" }}
                onClick={() => setActive(item.type)}
              >
                {item.text}
              </Box>
            ))}
          </Box>
          <Box w="full">
            <Heading as="h1" fontSize="2xl" mb="10">
              {quickQuestionsData.title}
            </Heading>
            <QuickQuestionsComp
              questions={quickQuestionsData?.qAnswer?.filter(
                (answer) => answer.type === active
              )}
              btnText={quickQuestionsData.buttonText}
            />
          </Box>
        </Stack>
      </Container>
    </Main>
  );
};

export default QuickQuestions;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
